import React from "react";
import clsx from "clsx";
import "../../src/App.css";
import { useSelector, useDispatch } from "react-redux";

import asyncComponent from "./asyncComponent";
const LandingPage = React.lazy(() => import("./apps/Index/index"));
const Masterlist = React.lazy(() =>
  import("../components/apps/pocketHr/masterlist/index")
);
const PayrollGroup = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollGroup/index")
);
const CreateQuickAccount = React.lazy(() =>
  import("./apps/pocketHr/CreateQuickAccount/CreateQuickAccount")
);
const Employeelist = React.lazy(() =>
  import("../components/apps/pocketHr/employeelist/index")
);
const Timesheet = React.lazy(() =>
  import("../components/apps/pocketHr/Timesheet/index")
);
const Monitoring = React.lazy(() =>
  import("../components/apps/pocketHr/Monitoring/index")
);
const NotFound = React.lazy(() =>
  import("../components/apps/error404/error404")
);
const PayrollEmployee = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollEmployeeList/index")
);
const Accomplishments = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Accomplishments/Accomplishments")
);
const LeaveSheet = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Employee Leave Sheet/Employee_Leave_Sheet")
);
const OBSheet = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Employee OB Sheet/Employee_OB_Sheet")
);
const OTSheet = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Employee OT Sheet/Employee_OT_Sheet")
);
const IncidentReport = React.lazy(() =>
  import("./apps/pocketHr/Incident/IncidentReport")
);
const PayrollDetails = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollDetails/payrollDetails")
);
const UndertimeSheet = React.lazy(() =>
  import(
    "./apps/pocketHr/Timesheet/Employee Undertime Sheet/Employee_Undertime_Sheet"
  )
);
const EmployeeSheet = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Employee Time Sheet/Employee_Time_Sheet")
);
const DTR = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Employee Time Sheet/DTR")
);

const Holidays = React.lazy(() =>
  import("./apps/pocketHr/Payroll/Holidays/index")
);
const Earnings = React.lazy(() =>
  import("./apps/pocketHr/Payroll/Retro/Earnings/earning")
);
const Deductions = React.lazy(() =>
  import("./apps/pocketHr/Payroll/Retro/Deductions/deduction")
);
const govDues = React.lazy(() =>
  import("./apps/pocketHr/Payroll/Government Dues/governmentDues")
);
// const Logs = asyncComponent(() =>
//   import("./apps/pocketHr/Monitoring/logs/logs")
// );
const Dashboard = asyncComponent(() =>
  import("./apps/pocketHr/Monitoring/dashboard/index")
);
const CompanySettings = React.lazy(() =>
  import("./apps/system_settings/company_settings/company_settings")
);
const ScheduleSettings = React.lazy(() =>
  import("./apps/system_settings/schedule_settings/schedule_settings")
);
const Late = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollSettings/late")
);
const AdditionalAdjustment = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollAdjustment/Additional/additional")
);
const Overwrite = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollSettings/overwrite")
);
const DeductionAdjustment = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollAdjustment/Deduction/deduction")
);
const PayrollHierarchy = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollSettings/payroll_hierarchy")
);
const HolidaySettings = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollSettings/holiday_settings")
);
const AdminControl = React.lazy(() =>
  import("./apps/app_settings/admin_control")
);
const SalarySettings = React.lazy(() =>
  import("./apps/pocketHr/Payroll/SalarySettings/salarysettings")
);
const AccountPriviledge = React.lazy(() =>
  import("./apps/system_settings/account_priviledge/account_priviledge")
);
const NavigationSettings = React.lazy(() =>
  import("./apps/system_settings/navigation_settings/navigationsettings")
);
const MobilePriviledge = React.lazy(() =>
  import("./apps/system_settings/department_settings/department_settings")
);
const BranchSettings = React.lazy(() =>
  import("./apps/system_settings/branch_settings/branch_settings")
);

const DepartmentSettings = React.lazy(() =>
  import("./apps/system_settings/department_settings/department_settings")
);
const PositionSettings = React.lazy(() =>
  import("./apps/system_settings/position_settings/position_settings")
);
const AccountSettings = React.lazy(() =>
  import("./apps/system_settings/account_settings/accountsettings")
);
const UploadTimeSheet = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Upload Timesheet/uploadTimesheet")
);
const UploadDoubleBook = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Upload Timesheet/uploadDoubleBook")
);
const UploadJobOrder = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Upload Timesheet/uploadJobOrder")
);
const Mail = React.lazy(() => import("./apps/mail/mail"));
const ScheduleSettingsFieldman = React.lazy(() =>
  import("./apps/system_settings/schedule_fieldman/schedule_fieldman")
);
const ThirteenthMonthPay = React.lazy(() =>
  import("./apps/pocketHr/Payroll/ThirteenMonthPay/index")
);
const ManualAbsent = React.lazy(() =>
  import("./apps/pocketHr/ManualFiling/Absent/absent")
);
const ManualLeave = React.lazy(() =>
  import("./apps/pocketHr/ManualFiling/Leave/leave")
);
const ThirteenMonthPayDetails = React.lazy(() =>
  import("./apps/pocketHr/Payroll/ThirteenMonthPay/thirteenMonth_details")
);

const EmployeeThirteenMonth = React.lazy(() =>
  import("./apps/pocketHr/Payroll/ThirteenMonthPay/employee_thirteenMonth")
);
const SalarySchedule = React.lazy(() =>
  import("./apps/system_settings/salary_schedule/salary_schedule")
);
const Inventory = React.lazy(() =>
  import("./apps/pocketHr/equipments/inventory/inventory")
);
const Inventory_Item = React.lazy(() =>
  import("./apps/pocketHr/equipments/inventory_item/inventory_item")
);
const MemoMonitoringIndex = React.lazy(() =>
  import("./apps/pocketHr/Monitoring/monitoring_memo/index")
);

const Vehicles = React.lazy(() =>
  import("./apps/pocketHr/vehicles/Vehicle/vehicle")
);
const FuelPortal = React.lazy(() =>
  import("./apps/pocketHr/vehicles/Portal/portal")
);
const VehiclesDash = React.lazy(() =>
  import("./apps/pocketHr/vehicles/Dashboard/dashboard")
);
// const PendingRequest = React.lazy(() =>
//   import("./apps/pocketHr/vehicles/Cheque/cheque")
// );
const BudgetPortal = React.lazy(() =>
  import("./apps/system_settings/req_hierarchy_settings/index")
);
const MonitoringSheet = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Monitoring Sheet/Monitoring_Sheet")
);
const ScheduleFieldman = React.lazy(() =>
  import("./apps/pocketHr/Scheduling/index")
);
const DocSheet = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Employee_Docs_Sheet/Employee_Docs_Sheet")
);
const WFHSheet = React.lazy(() => import("./apps/pocketHr/Timesheet/WFH/WFH"));
const PayrollRule = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollRule/index")
);
const EmpAppeal = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Employee Appeal/employee_appeal")
);

const ReqHierarchySettings = React.lazy(() =>
  import("./apps/system_settings/req_hierarchy_settings/index")
);
const UploadTrips = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Upload Timesheet/uploadTrips")
);
const Trips = React.lazy(() => import("./apps/pocketHr/trips/index"));
const Memo = React.lazy(() => import("./apps/pocketHr/Memorandum/index"));
const RequestForPayment = React.lazy(() =>
  import("./apps/pocketHr/Finance/RequestForPayment/index")
);
const MemoAbsent = React.lazy(() =>
  import("./apps/pocketHr/Memorandum/absent/absent")
);
const QuestionBank = React.lazy(() =>
  import("./apps/system_settings/question_bank/question_bank")
);
const ApplicantList = React.lazy(() =>
  import("./apps/pocketHr/applicant/index")
);
const EmployeeGrading = React.lazy(() =>
  import("./apps/pocketHr/Grading/grading")
);
const GradingCriteria = React.lazy(() =>
  import("./apps/system_settings/grading_criteria/grading_criteria")
);

const PayrollReports = React.lazy(() =>
  import("./apps/pocketHr/Reports/PayrollReport/indexPayroll")
);
const PayrollRequest = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollRequest/payrollRequest")
);
const PayrollEmployeeList = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollRequest/payrollEmployeeList")
);
const IndexDeductionLoans = React.lazy(() =>
  import("./apps/pocketHr/Payroll/EarningDeduction/Deductions/indexDeductions")
);
const IndexEarning = React.lazy(() =>
  import("./apps/pocketHr/Payroll/EarningDeduction/Earnings/indexEarning")
);
const IndexAllBranch = React.lazy(() =>
  import(
    "./apps/pocketHr/Reports/PayrollReport/AllBranchReports/indexAllBranch"
  )
);
const IndexTabs = React.lazy(() =>
  import("./apps/pocketHr/Reports/PayrollReport/indexTabs")
);
const IndexTabPayroll = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollGroup/indexTab")
);
const Visitor = React.lazy(() => import("./apps/pocketHr/Visitor/visitor"));
const IndexAccess = React.lazy(() =>
  import("./apps/pocketHr/userAccess/indexAccess")
);
const IndexPayrollBatch = React.lazy(() =>
  import(
    "./apps/pocketHr/Payroll/PayrollSettings/PayrollBatchV2/indexPayrollBatch"
  )
);
const PayrollRuleV2 = React.lazy(() =>
  import("./apps/pocketHr/Payroll/PayrollRuleV2/index")
);
const IndexAttendanceMonitoring = React.lazy(() =>
  import("./apps/pocketHr/AttendanceMonitoring/indexAttendanceMonitoring")
);
const EmployeeStraightDuty = React.lazy(() =>
  import(
    "./apps/pocketHr/Timesheet/Employee Straight Duty/EmployeeStraightDuty"
  )
);
const AdvanceDuty = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Advance Duty/AdvanceDuty")
);
const DailyScheduling = React.lazy(() =>
  import("./apps/system_settings/dailySchecduling/dailyScheduling")
);
const PendingRequest = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Pending Request/PendingRequest")
);
const RequestSheet = React.lazy(() =>
  import("./apps/pocketHr/Timesheet/Request_Sheet/request_sheet")
);
function App(page) {
  const userDetails = useSelector((state) => state.Navigation);
  let path = LandingPage;
  let matchPage = false;
  userDetails.appNav.map((val) => {
    if (page == val.page_name) {
      matchPage = true;
    }
  });
  if (page == "Default") {
    return LandingPage;
  }
  if (page == "Mail") {
    return Mail;
  }
  if (matchPage == true) {
    if (page == "Master List") {
      return Masterlist;
    }
    if (matchPage == true) {
      if (page == "Master List") {
        return Masterlist;
      }
      if (page == "Employee List") {
        return Employeelist;
      } else if (page == "Attendance Monitoring") {
        return IndexAttendanceMonitoring;
      } else if (page == "Create Quick Account") {
        return CreateQuickAccount;
      } else if (page == "Leave Sheet") {
        return LeaveSheet;
      } else if (page == "OB Sheet") {
        return OBSheet;
      } else if (page == "Overtime Sheet") {
        return OTSheet;
      } else if (page == "Incident Report") {
        return IncidentReport;
      } else if (page == "Undertime Sheet") {
        return UndertimeSheet;
      } else if (page == "Employee Sheet") {
        return EmployeeSheet;
      } else if (page == "DTR") {
        return DTR;
      } else if (page == "Attendance") {
        return Timesheet;
      } else if (page == "Payroll Group") {
        return PayrollGroup;
      } else if (page == "Payroll Employee") {
        return PayrollEmployee;
      } else if (page == "Payroll Details") {
        return PayrollDetails;
      } else if (page == "Holidays") {
        return Holidays;
      } else if (page == "Retro Earnings") {
        return Earnings;
      } else if (page == "Retro Deductions") {
        return Deductions;
      } else if (page == "Government Dues") {
        return govDues;
      }
      //  else if (page == "App Logs") {
      //   return Logs;
      // }
      else if (page == "Attendance Dashboard") {
        return Dashboard;
      } else if (page == "Company Settings") {
        return CompanySettings;
      } else if (page == "Schedule Settings") {
        return ScheduleSettings;
      } else if (page == "Late Settings") {
        return Late;
      } else if (page == "Additional Adjustment") {
        return AdditionalAdjustment;
      } else if (page == "Overwrite") {
        return Overwrite;
      } else if (page == "Deduction Adjustment") {
        return DeductionAdjustment;
      } else if (page == "Payroll Hierarchy") {
        return PayrollHierarchy;
      } else if (page == "Holiday And Wages Rate") {
        return HolidaySettings;
      } else if (page == "Admin Control") {
        return AdminControl;
      } else if (page == "Salary Settings") {
        return SalarySettings;
      } else if (page == "Account Priviledge") {
        return AccountPriviledge;
      } else if (page == "Navigation Settings") {
        return NavigationSettings;
      } else if (page == "Branch Settings") {
        return BranchSettings;
      } else if (page == "Position Settings") {
        return PositionSettings;
      } else if (page == "Account Settings") {
        return AccountSettings;
      } else if (page == "Upload TimeSheet") {
        return UploadTimeSheet;
      } else if (page == "Upload DoubleBook") {
        return UploadDoubleBook;
      } else if (page == "Upload JobOrder") {
        return UploadJobOrder;
      } else if (page == "Upload Trips") {
        return UploadTrips;
      } else if (page == "Mobile Priviledge") {
        return MobilePriviledge;
      } else if (page == "Accomplishments") {
        return Accomplishments;
      } else if (page == "Time Table Settings") {
        return DailyScheduling;
      } else if (page == "Thirteenth Month Pay") {
        return ThirteenthMonthPay;
      } else if (page == "Manual Absent") {
        return ManualAbsent;
      } else if (page == "Manual Leave") {
        return ManualLeave;
      } else if (page == "Thirteen Month Pay Details") {
        return ThirteenMonthPayDetails;
      } else if (page == "Employee Thirteen Month") {
        return EmployeeThirteenMonth;
      } else if (page == "Trip Rates") {
        return SalarySchedule;
      } else if (page == "Inventory") {
        return Inventory;
      } else if (page == "Inventory Item") {
        return Inventory_Item;
      } else if (page == "Memo Monitoring") {
        return Memo;
      } else if (page == "Vehicles") {
        return Vehicles;
      } else if (page == "Vehicle Dashboard") {
        return VehiclesDash;
      } else if (page == "Pending Requests") {
        return PendingRequest;
      } else if (page == "Fuel Portal") {
        return FuelPortal;
      } else if (page == "Budget Portal") {
        return BudgetPortal;
      } else if (page == "Monitoring Sheet") {
        return MonitoringSheet;
      } else if (page == "Scheduling PocketHr") {
        return ScheduleFieldman;
      } else if (page == "Document Sheet") {
        return DocSheet;
      } else if (page == "Work From Home") {
        return WFHSheet;
      } else if (page == "Payroll Rules") {
        return PayrollRule;
      } else if (page == "Employee Appeal") {
        return EmpAppeal;
      } else if (page == "Request Hierarchy Settings") {
        return ReqHierarchySettings;
      } else if (page == "Trips") {
        return Trips;
      } else if (page == "Request For Payment") {
        return RequestForPayment;
      } else if (page == "NTE Absent") {
        return MemoAbsent;
      } else if (page == "Question Bank") {
        return QuestionBank;
      }
      if (page == "Applicant List") {
        return ApplicantList;
      }
      if (page == "Employee Grading") {
        return EmployeeGrading;
      }
      if (page == "Grading Criteria") {
        return GradingCriteria;
      } else if (page == "Payroll Reports") {
        return IndexTabs;
      } else if (page == "Payroll Requests") {
        return PayrollRequest;
      } else if (page == "Payroll Employee List") {
        return PayrollEmployeeList;
      } else if (page == "Visitor Table") {
        return Visitor;
      } else if (page == "Payroll Batch") {
        return IndexPayrollBatch;
      } else if (page == "EarningsV2") {
        return IndexEarning;
      } else if (page == "Payroll Rule V2") {
        return PayrollRuleV2;
      } else if (page == "Department Settings") {
        return DepartmentSettings;
      } else if (page == "DeductionsV2") {
        return IndexDeductionLoans;
      } else if (page == "Access Priviledges V2") {
        return IndexAccess;
      } else if (page == "Attendance Monitoring Version2") {
        return IndexAttendanceMonitoring;
      } else if (page == "Change Duty") {
        return EmployeeStraightDuty;
      } else if (page == "Advance Duty") {
        return AdvanceDuty;
      } else if (page == "Request Sheet") {
        return RequestSheet;
      }
    }
  } else {
    return NotFound;
  }
}

export default App;
