import axios from "axios";
// import swal from "sweetalert/dist/sweetalert.min.js";
// let serverurl = "http://localhost/api/";
// let serverurl = "http://beta.api.pacificweb.com.ph/";
let serverurl = "https://api.workflow.gzonetechph.com/";

// let serverurl = "http://61.245.28.186:14143/backend/api.workflow/";

// let serverurl = "http://192.168.0.10:14143/backend/api.workflow/";
// "homepage": "/apps/localhr",
// "proxy": "http://localhost",

// let serverurl = "https://workflowapi.usiphil.com/";
// let serverurl = "https://beta.workflow.gzonetechph.com/";

let key = "?key=PocketHR@20190208&type=web";
// let key2 = "?key=12345ABCFleet";
const CancelToken = axios.CancelToken;
let source = CancelToken.source();
export function getData(method, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        serverurl + method + key,
        {
          param,
        },
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        resolve(res.data);
      });
  });
}
export function cancelRequest() {
  source.cancel("Operation canceled by the user.");
  source = CancelToken.source();
}


// export const apiurl = serverurl;
// export const serverImageMeter = serverurl + "assets/img/meter/";
// export const loginurl = "https://login.usiphil.com/";
// export const serverProfile =
//   "https://images.usiphil.com/pockethr/profilepic/";
// export const serverImage =
//   "https://images.usiphil.com/";

  export const apiurl = serverurl;
  export const serverImageMeter = serverurl + "assets/img/meter/";
  export const loginurl = "https://login.pockethr.gzonetechph.com/";
  export const serverProfile =
    "https://images.workflow.gzonetechph.com/pockethr/profilepic/";
  export const serverImage =
    "https://images.workflow.gzonetechph.com/";


  // export const apiurl = serverurl;
  // export const serverImageMeter = serverurl + "assets/img/meter/";
  // export const loginurl = "http://61.245.28.186:14143/apps/local_login";
  // export const serverProfile =
  //   "https://images.workflow.gzonetechph.com/pockethr/profilepic/";
  // export const serverImage =
  //   "https://images.workflow.gzonetechph.com/";



  // export const apiurl = serverurl;
  // export const serverImageMeter = serverurl + "assets/img/meter/";
  // export const loginurl = "http://192.168.0.10:14143/apps/internal_login";
  // export const serverProfile =
  //   "https://images.workflow.gzonetechph.com/pockethr/profilepic/";
  // export const serverImage =
  //   "https://images.workflow.gzonetechph.com/";